// App.js

import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import { FiLogOut } from 'react-icons/fi'; // Importing Logout icon from react-icons

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Original App URL
const Original_App_URL = process.env.REACT_APP_ORIGINAL_APP_URL;

// Function to get or create a long-lived token
async function getOrCreateToken(userId) {
  let { data: token, error } = await supabase
    .from('tokens')
    .select('long_lived_token')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') {
    throw error;
  }

  if (!token) {
    const newToken = uuidv4();
    const { data, error: insertError } = await supabase
      .from('tokens')
      .insert({ user_id: userId, long_lived_token: newToken })
      .select('long_lived_token')
      .single();

    if (insertError) throw insertError;
    token = data;
  }

  return token.long_lived_token;
}

// Function to handle posting the token and car ID
function postToOriginalApp(token, carid) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = Original_App_URL;

  const addHiddenField = (name, value) => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  };

  addHiddenField('usertoken', token);
  addHiddenField('carid', carid);

  document.body.appendChild(form);
  form.submit();
}

// CarSelection Component
function CarSelection({ token }) {
  const [availableCars, setAvailableCars] = useState([]);
  const [message, setMessage] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  useEffect(() => {
    const carsEnv = process.env.REACT_APP_AVAILABLECARS || 'n1,n2,n3';
    const carsArray = carsEnv
      .split(',')
      .map((car) => car.trim())
      .filter((car) => car !== '');
    setAvailableCars(carsArray);
  }, []);

  const handleSelectCar = async (carid) => {
    setMessage('');
    setIsPosting(true);
    try {
      await postToOriginalApp(token, carid);
    } catch (error) {
      console.error('Error posting to Original App:', error.message);
      setMessage(`Error: ${error.message}`);
      setIsPosting(false);
    }
  };

  const handleRandomSelect = () => {
    if (availableCars.length === 0) {
      setMessage('No available cars to select.');
      return;
    }
    const randomIndex = Math.floor(Math.random() * availableCars.length);
    const randomCarid = availableCars[randomIndex];
    handleSelectCar(randomCarid);
  };

  // Logout function with confirmation
  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      Cookies.remove('user_token', { path: '/' }); // Remove the token cookie
      window.location.href = '/'; // Redirect to the login page
    }
  };

  return (
    <div style={styles.container}>
      {/* Logout Button Positioned at Top-Right Corner */}
      <button
        onClick={handleLogout}
        style={styles.logoutButton}
        aria-label="Logout"
        title="Logout"
      >
        Logout <FiLogOut size={20} style={{ marginLeft: '8px' }} />
      </button>

      <h2 style={styles.title}>Select ChatGPT Plus Account</h2>
      <div style={styles.buttonContainer}>
        <button
          onClick={handleRandomSelect}
          style={styles.button}
          disabled={isPosting}
        >
          {isPosting ? 'Processing...' : 'Random'}
        </button>
        {availableCars.map((carid) => {
          const carNumber = carid.replace('n', '');
          return (
            <button
              key={carid}
              onClick={() => handleSelectCar(carid)}
              style={styles.button}
              disabled={isPosting}
            >
              GPT Plus Account #{carNumber}
            </button>
          );
        })}
      </div>
      {message && <p style={styles.message}>{message}</p>}

      <div style={styles.notesContainer}>
        <p style={styles.note}>
          <strong>Information:</strong> If a node is off-line you will be returned to this screen.
        </p>
        <p style={styles.note}>
          <strong>Reminder:</strong> All ChatGPT Plus models and more are available on
          <a href="https://smart.ultimateai.org" style={styles.link}> Smart</a>
        </p>
      </div>
    </div>
  );
}

// LoginForm Component
function LoginForm({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const { user, error } = await supabase.auth.signIn({
        email,
        password,
      });

      if (error) throw error;

      const longLivedToken = await getOrCreateToken(user.id);

      // Save token as cookie with 48 days expiration
      Cookies.set('user_token', longLivedToken, { expires: 48, path: '/' });

      // Call the success handler to perform post-login actions
      onLoginSuccess(longLivedToken);
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }
    try {
      const { error } = await supabase.auth.api.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img
          src="https://auth.openai.com/assets/openai-logo-DmWoKcI3.svg"
          alt="Logo"
          style={styles.logo}
        />
      </div>
      <h2 style={styles.title}>Welcome back</h2>
      <form onSubmit={handleLogin} style={styles.form}>
        <label style={styles.label}>Email address*</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={styles.input}
          required
        />
        <label style={styles.label}>Password*</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={styles.input}
          required
        />
        <button
          type="button"
          onClick={handleForgotPassword}
          style={styles.forgotPassword}
        >
          Forgot password?
        </button>
        <div style={styles.buttonPadding}></div>
        <button type="submit" style={styles.button}>
          Continue
        </button>
        {message && <p style={styles.message}>{message}</p>}
      </form>
      <p style={styles.disclaimer}>
        Please note: This application will remain logged in for a number of days.
        If you are using a shared or public computer, use private or incognito
        mode.
      </p>
    </div>
  );
}

// PasswordReset Component
function PasswordReset() {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const accessToken = supabase.auth.session()?.access_token;
      if (!accessToken) {
        setMessage('No valid reset token found');
        return;
      }

      // Update the user's password
      const { error } = await supabase.auth.api.updateUser(accessToken, {
        password: newPassword,
      });

      if (error) throw error;

      setMessage('Password has been reset successfully');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Password reset error:', error);
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img
          src="https://auth.openai.com/assets/openai-logo-DmWoKcI3.svg"
          alt="Logo"
          style={styles.logo}
        />
      </div>
      <h2 style={styles.title}>Reset Password</h2>
      <form onSubmit={handleResetPassword} style={styles.form}>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          style={styles.input}
          required
        />
        <div style={styles.buttonPadding}></div>
        <button type="submit" style={styles.button}>
          Reset Password
        </button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

// AcceptInvite Component
function AcceptInvite({ token }) {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!token) {
      setMessage('Invalid invitation link');
    } else {
      setMessage('Please set your password to accept the invitation.');
    }
  }, [token]);

  const handleAcceptInvite = async (e) => {
    e.preventDefault();
    if (!token) {
      setMessage('Invalid invitation link');
      return;
    }

    try {
      const { error } = await supabase.auth.api.updateUser(token, {
        password: password,
      });

      if (error) throw error;

      setMessage('Invitation accepted successfully. You can now log in.');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Accept Invitation</h2>
      <form onSubmit={handleAcceptInvite} style={styles.form}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Set your password"
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>
          Accept Invitation
        </button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

// Main App Component
function App() {
  const location = useLocation();
  const [inviteToken, setInviteToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const type = searchParams.get('type');

    if (token && type === 'invite') {
      setInviteToken(token);
    }
  }, [location]);

  useEffect(() => {
    const checkSession = async () => {
      const token = Cookies.get('user_token');
      if (token) {
        setUserToken(token);
        setIsAuthenticated(true);
      }
      setIsLoading(false); // Finished checking session
    };

    checkSession();
  }, []);

  const handleLoginSuccess = async (token) => {
    // Automatically select a random car and post
    const carsEnv = process.env.REACT_APP_AVAILABLECARS || 'n1,n2,n3';
    const carsArray = carsEnv
      .split(',')
      .map((car) => car.trim())
      .filter((car) => car !== '');

    if (carsArray.length === 0) {
      console.error('No available cars to select.');
      return;
    }

    const randomIndex = Math.floor(Math.random() * carsArray.length);
    const randomCarid = carsArray[randomIndex];

    postToOriginalApp(token, randomCarid);
  };

  if (isLoading) {
    return (
      <div style={styles.container}>
        <p>Loading...</p>
      </div>
    );
  }

  if (inviteToken) {
    return <AcceptInvite token={inviteToken} />;
  }

  if (isAuthenticated) {
    return <CarSelection token={userToken} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<LoginForm onLoginSuccess={handleLoginSuccess} />}
      />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

const styles = {
  container: {
    position: 'relative', // To position the logout button relative to this container
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#ffffff',
    padding: '20px',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333333',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  label: {
    marginBottom: '5px',
    fontSize: '14px',
    color: '#333333',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '16px',
    borderColor: '#00a884',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#00a884',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '10px',
    width: '100%',
  },
  message: {
    marginTop: '10px',
    color: 'red',
    textAlign: 'center',
  },
  forgotPassword: {
    color: '#00a884',
    background: 'none',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: '10px',
    alignSelf: 'flex-end',
  },
  buttonPadding: {
    height: '10px',
  },
  disclaimer: {
    marginTop: '20px',
    fontSize: '12px',
    color: '#666666',
    textAlign: 'center',
    maxWidth: '300px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  logoutButton: {
  position: 'absolute',
  top: '20px',
  right: '20px',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#00a884',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  },
};

// AppWrapper Component with Router
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}